@import "../App.scss";

.dialogue-container {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  &.light{
    background-color: rgba($color: #ffffff, $alpha: 0.5);
    .dialogue{
      background-color: $secondaryLight;
      h1{
        color: $primaryDark;
      }
      p{
        color: $primaryDark;
      }
      button{
        background-color: $secondaryDark;
        color: $primaryDark;
        &:hover{
          background-color: $secondary;
          color: $primaryLight;
        }
      }
    }
  }

  .dialogue {
    width: 400px;
    border-radius: 10px;
    padding: 20px;
    background-color: $primaryLight;
    position: relative;
    h1{
      font-size: 1.5rem;
      font-weight: 300;
      padding: 0;
    }
  
    p{
      font-size: 1rem;
      font-weight: 300;
      padding: 0;
      text-align: justify;
    }
    button{
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      border: none;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      background-color: $secondary;
      color: $primaryDark;

      &:hover{
        background-color: $secondaryLight;
        color: $primaryLight;
      }
    }
  }
}