@import '../App.scss';

.sudoku{
  min-height: 100vh;
  min-width: 750px;
  width: calc(100vw - 250px);
  color: white;
  position: relative;
  left: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  .sudoku-container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    .dialogue{
      h1{
        font-size: 2rem;
        margin: 0;
        padding: 0;
      }
      .options{
        width: 100%;
        display: flex;
        gap: 15px;
        justify-items: center;
        align-items: center;
        margin-top: 20px;

        label{
          height: 40px;
          padding: 0 10px;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
          font-size: 1.2rem;
          font-weight: bold;
          color: white;
          background-color: $primary;
          border: none;
          border-radius: 10px;

          &.selected {
              background-color: darken($primary, 10%);
          }
          input{
            display: none;
          }
        }
      }
      .buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        button{
          width: 100px;
          height: 40px;
          border: none;
          border-radius: 20px;
          font-size: 1rem;
          font-weight: bold;
          color: $primary;
          background-color: $secondaryLight;
          cursor: pointer;
          &:hover {
            background-color: $secondary;
          }
        }
      }
    }
    .sudoku-wrapper {
      width: 500px;
      height: 100vh;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .win{
          z-index: 2;
          position: absolute;
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          h1{
              font-size: 10rem;
              color: crimson;
              text-shadow: 15px 10px 0 black;
              animation: text-animation 1s ease-in;
          }
          @keyframes text-animation {
              0% {
                  transform:scale(1);
              }
              50% {
                  transform:scale(2);
              }
              100% {
                  transform:scale(1);
              }
          }
      }

      h1 {
          font-size: 3rem;
          margin: 0;
          padding: 0;
          span{
            font-size: 1rem;
          }
      }

      .sudoku-grid {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0;
          border: 3px solid $secondary;
          margin: 30px 0;
          background-color: #fff;

          .sudoku-row {
              flex: 1;
              display: flex;
              gap: 0;

              &:nth-child(3) {
                  border-bottom: 2px solid $secondary;
              }

              &:nth-child(6) {
                  border-bottom: 2px solid $secondary;
              }

              .sudoku-cell {
                  flex: 1;
                  align-items: center;
                  border: 1px solid $secondary;
                  width: 50px;
                  height: 50px;

                  &:nth-child(3) {
                      border-right: 3px solid $secondary;
                  }

                  &:nth-child(6) {
                      border-right: 3px solid $secondary;
                  }

                  input {
                      width: 100%;
                      height: 100%;
                      text-align: center;
                      font-size: 42px;
                      color: $primaryLight;
                      border: none;
                      padding: 0;
                      caret-color: transparent;

                      &:focus {
                          outline: none;
                      }
                      &::-webkit-outer-spin-button,&::-webkit-inner-spin-button {
                          -webkit-appearance: none;
                          margin: 0;
                      }
                  }

                  &.fixed {
                      input {
                          color: $primaryDark;
                      }
                  }

                  &.highlighted {
                      input {
                          background-color: lightcyan;
                      }
                  }

                  &.focused {
                      input {
                          background-color: $secondary;
                      }
                  }

                  &.same-number {
                      input {
                          color: rgb(156, 31, 56);
                      }
                  }

                  &.red {
                      input{
                          background-color: pink;
                      }
                  }

              }
          }
      }

      .buttons {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          gap: 20px;
          z-index: 3;

          button {
              width: 100px;
              height: 40px;
              border: none;
              border-radius: 20px;
              font-size: 1rem;
              font-weight: bold;
              color: $primary;
              background-color: $secondaryLight;
              cursor: pointer;

              &:hover {
                  background-color: $secondary;
              }
          }
      }
  }
  }
}