@import "../App.scss";

.home {
  box-shadow: 10px 0 10px $primaryDark;
  min-height: 100vh;
  color: white;
  position: relative;
  display: flex;

  .sidebar {
    position: fixed;
    width: 250px;
    height: 100vh;
    background: $primary;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    header {
      h1 {
        color: white;
        text-align: left;
        margin: 0;
        font-size: 1.5rem;
      }

      p {
        color: white;
        text-align: left;
        margin: 0;
        font-size: 0.8rem;
      }
    }

    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        width: 100%;
        background: $primaryDark;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background: $primaryLight;
        }

        &:first-child {
          border-radius: 5px 5px 0 0;
          border-bottom: 1px solid $primary;
        }

        &:last-child {
          border-radius: 0 0 5px 5px;
        }

        a{
          color: white;
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: block;
          padding: 10px 20px;
        }
      }
    }

    footer{
      width: 100%;
      font-size: 0.8rem;

      a{
        color: white;
        text-decoration: none;
        font-weight: bold;
        background-color: $secondaryDark;
        padding: 3px 6px;
        border-radius: 5px;
      }
    }
  }
}